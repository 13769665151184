import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Link, Trans } from 'gatsby-plugin-react-i18next'

export default function Header(props) {

  const { siteTitle, menuLinks } = props

  return(

    <header id="Header">
      <div className="container py-1 g-0">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Link
                  to="/"
                  style={{
                    color: `white`,
                    textDecoration: `none`,
                  }}
                  className="navbar-brand"
                >
                  <StaticImage
                    src="../images/logo.png"
                    width={199}
                    quality={94}
                    formats={["auto", "webp", "avif"]}
                    alt={siteTitle}
                  />
                </Link>

                <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerMainMenu" aria-controls="navbarTogglerMainMenu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon text-white">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-bars fa-w-14 fa-2x"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerMainMenu">
                  <ul style={{ display: "flex", flex: 1 }} className="navbar-nav me-auto mb-2 mb-lg-0 list-unstyled justify-content-end">
                    {menuLinks.map(link => (
                      <li key={link.name} className="p-0 m-0 px-4 list-inline-item" >
                        <Link to={link.link} activeClassName="active" className="text-decoration-none text-uppercase">
                          <Trans>{link.name}</Trans>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>

        </div>

      </div>
    </header>

  )

} 

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}