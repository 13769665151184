import * as React from "react"
import { Link, Trans } from 'gatsby-plugin-react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faMobileAlt } from '@fortawesome/free-solid-svg-icons'

export default function Header(props) {

    const { config } = props
    const { siteTitle, menuLinks } = props

    let phone1 = config.phone1
    phone1 = phone1.replace(/[^a-zA-Z0-9]/g, "");
    let phone2 = config.phone2
    phone2 = phone2.replace(/[^a-zA-Z0-9]/g, "");

    return(

        <footer id="Footer" className="footer bg-black text-white py-5">
            <div className="container py-1 g-0">
                <div className="row">
                    <div className="col-lg-3 d-none d-lg-block text-left">
                        <h3>{siteTitle}</h3>
                        <nav className="navbar">
                            <ul className="navbar-nav m-0 list-unstyled">
                            {menuLinks.map(link => (
                                <li key={link.name} className="p-0 m-0" >
                                    <Link to={link.link} className="text-uppercase">
                                        <Trans>{link.name}</Trans>
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </nav>
                    </div>

                    <div className="col-12 col-lg-6">
                        <h3><Trans>FOOTER About us Title</Trans></h3>
                        <Trans>FOOTER About us Text</Trans>
                    </div>

                    <div className="col-12 col-lg-3">
                        <h3><Trans>FOOTER Contact us Title</Trans></h3>
                        <p><Trans>FOOTER Contact us Text</Trans></p>
                        <ul className="p-0 m-0 list-unstyled">
                            <li className="mb-1">
                                <FontAwesomeIcon icon={faEnvelope} className="me-1" /> 
                                <a href={`mailto:` + config.email} target="_blank" rel="noreferrer" className="text-decoration-none text-white">
                                    {config.email}
                                </a>
                            </li>
                            <li className="mb-1">
                                <FontAwesomeIcon icon={faPhone} className="me-1" /> 
                                <a href={`tel:` + phone1} target="_blank" rel="noreferrer" className="text-decoration-none text-white">
                                    {config.phone1}
                                </a>
                            </li>
                            <li className="mb-1">
                                <FontAwesomeIcon icon={faMobileAlt} className="me-1" /> 
                                <a href={`tel:` + phone2} target="_blank" rel="noreferrer" className="text-decoration-none text-white">
                                    {config.phone2}
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </footer>

    )

}