import * as React from "react"

export default function Copyright(props) {

    return(

        <section id="Copyright" className="py-2">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        {new Date().getFullYear()} Luis General Contractor. All Rights Reserved. Designed By <a href='https://www.creandowebs.com' target="_blank" rel="noreferrer">CreandoWEBS.com</a>
                    </div>
               </div>

            </div>
        </section>

    )

}