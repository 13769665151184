import * as React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from "gatsby-plugin-image"

export default function Topbar(props) {
    
    const { config } = props
    const { languages, originalPath }  = useI18next()

    let phone1 = config.phone1
    phone1 = phone1.replace(/[^a-zA-Z0-9]/g, "");
    let phone2 = config.phone2
    phone2 = phone2.replace(/[^a-zA-Z0-9]/g, "");

    return (
        <section id="Topbar" className="topbar py-1">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-4">
                        <ul className="p-0 m-0 list list-unstyled list-inline">
                            {languages.map(lng => (
                                <li key={lng} className="list-inline-item mb-0">
                                    <Link to={originalPath} language={lng}>
                                        {lng==='es' ?
                                        <StaticImage
                                            src="../images/es.gif"
                                            width={18}
                                            quality={90}
                                            formats={["auto", "webp", "avif"]}
                                            alt={lng}
                                        />
                                        :
                                        <StaticImage
                                            src="../images/en.gif"
                                            width={18}
                                            quality={90}
                                            formats={["auto", "webp", "avif"]}
                                            alt={lng}
                                        />
                                        }
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-8 text-end">
                        <ul className="p-0 m-0 list unstyled list-inline">
                            <li className="list-inline-item mb-0">
                                <FontAwesomeIcon icon={faPhone} /> 
                                <a href={`tel:` + phone1} target="_blank" rel="noreferrer">
                                    {config.phone1}
                                </a>
                            </li>
                            <li className="list-inline-item mb-0">
                                <FontAwesomeIcon icon={faMobileAlt} /> 
                                <a href={`tel:` + phone2} target="_blank" rel="noreferrer">
                                    {config.phone2}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )

}